/* eslint-disable no-console */
import React from "react";
import { FormGroup } from "reactstrap";
import { UseTranslation } from "i18n/Translation";
import { getModelComboOptions, getFuelComboOptions } from "AWS/aws.jsx";
import { UseLoginContext } from "contexts/LoginContext.jsx";
import PropTypes from "prop-types";
import makes from "assets/data/makes.json";
import { mobileAwareSelect } from "variables/constants.jsx";

class FormFirst extends React.Component {
  modelBodyDoorsParams = {
    brandId: undefined,
    year: undefined
  };
  constructor(params) {
    super(params);

    const year = Array.from({ length: 21 }, (_, id) => ({
      value: new Date().getFullYear() - 20 + id,
      label: new Date().getFullYear() - 20 + id
    }));

    this.state = {
      disableCarModelSelect: true,
      disableCarFuelSelect: true,
      carMakeOptions: [],
      carModelOption: [],
      fuelEnumOptions: [],
      year
    };

    this.getModels = this.getModels.bind(this);
  }

  UNSAFE_componentWillMount() {
    this.setState({
      carMakeOptions: makes.data.map(mdata => ({
        value: mdata.id,
        label: mdata.name
      }))
    });
  }

  getCarFuel(modelId, year) {
    getFuelComboOptions(modelId, year)
      .then(data => {
        this.setState({
          fuelEnumOptions: data.map(mdata => ({
            value: mdata.value,
            label: this.props.translation.formatcarFuelTranslation(mdata.label)
          })),
          disableCarFuelSelect: false
        });
      })
      .catch(err => {
        console.log("ERROR ON getBrands ", err);
      });
  }

  getModels() {
    const { modelBodyDoorsParams } = this;

    // We check that we have every param available to do the request
    for (var property in modelBodyDoorsParams) {
      if (modelBodyDoorsParams[property]) {
        continue;
      }
      return;
    }

    getModelComboOptions(
      modelBodyDoorsParams.brandId,
      modelBodyDoorsParams.year
    )
      .then(data => {
        this.setState({
          carModelOption: data.map(mdata => ({
            value: mdata.value,
            label: this.props.translation.formatcarModelTranslation(mdata.label)
          })),
          disableCarModelSelect: false
        });
      })
      .catch(err => {
        console.log("ERROR ON getBrands ", err);
      });
  }

  render() {
    const { t } = this.props.translation;

    return (
      <div>
        <FormGroup>
          <h6 className="pd6">
            {t("home.form.brand")}
            {" *"}
          </h6>
          {mobileAwareSelect(
            "carBrand",
            val => {
              this.props.onChange({
                target: { name: "carBrand", value: val.label }
              });
              this.props.onChange({
                target: { name: "carBrandId", value: val.value }
              });
              this.modelBodyDoorsParams.brandId = val.value;
              this.getModels();
            },
            this.state.carMakeOptions,
            this.props.loginContext.loggedIn === false
          )}
        </FormGroup>
        <FormGroup>
          <h6 className="pd6">
            {t("home.form.year")}
            {" *"}
          </h6>
          {mobileAwareSelect(
            "carFirstRegistration",
            val => {
              this.props.onChange({
                target: { name: "carFirstRegistration", value: val.value }
              });
              this.modelBodyDoorsParams.year = val.value;
              this.getModels();
            },
            this.state.year,
            false
          )}
        </FormGroup>
        <FormGroup>
          <h6 className="pd6">
            {t("home.form.model")}
            {" *"}
          </h6>
          {mobileAwareSelect(
            "carModel",
            val => {
              var values = val.value.split("_");
              var carModelId = values[0];
              var carBody = values[1];
              var carNbDoors = values[2];

              this.props.onChange({
                target: { name: "carModelComboId", value: val.value } // only used for the get fuel, Horspower, ...
              });
              this.props.onChange({
                target: { name: "carModel", value: val.label }
              });
              this.props.onChange({
                target: { name: "carModelId", value: carModelId }
              });
              this.props.onChange({
                target: { name: "carBody", value: carBody }
              });
              this.props.onChange({
                target: { name: "carNbDoors", value: carNbDoors }
              });
              this.getCarFuel(val.value, this.modelBodyDoorsParams.year);
            },
            this.state.carModelOption,
            this.state.disableCarModelSelect
          )}
        </FormGroup>
        <FormGroup>
          <h6 className="pd6">
            {t("home.form.fuelType")}
            {" *"}
          </h6>
          {mobileAwareSelect(
            "carFuelEnum",
            val =>
              this.props.onChange({
                target: { name: "carFuelEnum", value: val.value }
              }),
            this.state.fuelEnumOptions,
            this.state.disableCarFuelSelect
          )}
        </FormGroup>
      </div>
    );
  }
}

FormFirst.propTypes = {
  translation: PropTypes.object.isRequired,
  loginContext: PropTypes.object.isRequired,
  onChange: PropTypes.func.isRequired
};

export default UseTranslation(UseLoginContext(FormFirst));
