import React from "react";
import { Row } from "reactstrap";
import { UseTranslation } from "i18n/Translation";
import PropTypes from "prop-types";
import ReactPlayer from "react-player";

import "./SectionVideo.css";

class SectionVideo extends React.Component {
  render() {
    const { t } = this.props.translation;
    const { translation } = this.props;
    const videos = translation.getVideos();

    return (
      <section className="section-video">
          <div className="text-center">
            <h2 className="sgtitle">{t("home.videosection1.title")}</h2>
          </div>
        <Row>
          {videos.map((elem, key) => (
            <div
              className={`video-element col-md-${12 / videos.length} col-sm-12 mt-5`}
              key={key}
            >
                <ReactPlayer
                    className='react-player'
                    url={elem}
                    width="100%"
                    height="100%"
                  />
            </div>
          ))}
        </Row>
      </section>
    );
  }
}

SectionVideo.propTypes = {
  translation: PropTypes.object.isRequired
};

export default UseTranslation(SectionVideo);
