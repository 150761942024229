import React from "react";
import { Input } from "reactstrap";
import Select from "react-select";

export const gearboxEnum = [
  { value: "M", label: "enum.gearbox.manual" },
  { value: "A", label: "enum.gearbox.automatic" },
  { value: "S", label: "enum.gearbox.semiautomatic" }
];

export const fuelEnum = [
  { value: "B", label: "enum.fuel.gas" },
  { value: "D", label: "enum.fuel.diesel" },
  { value: "E", label: "enum.fuel.electric" },
  { value: "G", label: "enum.fuel.lpg" },
  { value: "D-E", label: "enum.fuel.diesel-elec" },
  { value: "B-E", label: "enum.fuel.gas-elec" },
  { value: "O", label: "enum.fuel.other" }
];

export const bodyworkEnum = [
  { value: "B", label: "enum.fuel.gas" },
  { value: "D", label: "enum.fuel.diesel" },
  { value: "E", label: "enum.fuel.electric" },
  { value: "G", label: "enum.fuel.lpg" },
  { value: "D-E", label: "enum.fuel.diesel-elec" },
  { value: "B-E", label: "enum.fuel.gas-elec" },
  { value: "O", label: "enum.fuel.other" }
];

export const bodyTypeEnum = [
  { value: "citadine", label: "enum.bodyType.citadine" },
  { value: "cabriolet", label: "enum.bodyType.cabriolet" },
  { value: "coupe", label: "enum.bodyType.coupe" },
  { value: "suv/4x4", label: "enum.bodyType.suv" },
  { value: "berline", label: "enum.bodyType.berline" },
  { value: "break", label: "enum.bodyType.break" },
  { value: "utilitaire", label: "enum.bodyType.utilitaire" },
  { value: "monospace", label: "enum.bodyType.monospace" },
  { value: "autre", label: "enum.bodyType.autre" }
];

export const mileageComboOptions = [
  { value: 5000, label: "< 5.000 km" },
  { value: 10000, label: "10.000 km" },
  { value: 15000, label: "15.000 km" },
  { value: 20000, label: "20.000 km" },
  { value: 25000, label: "25.000 km" },
  { value: 30000, label: "30.000 km" },
  { value: 35000, label: "35.000 km" },
  { value: 40000, label: "40.000 km" },
  { value: 45000, label: "45.000 km" },
  { value: 50000, label: "50.000 km" },
  { value: 55000, label: "55.000 km" },
  { value: 60000, label: "60.000 km" },
  { value: 65000, label: "65.000 km" },
  { value: 70000, label: "70.000 km" },
  { value: 75000, label: "75.000 km" },
  { value: 80000, label: "80.000 km" },
  { value: 85000, label: "85.000 km" },
  { value: 90000, label: "90.000 km" },
  { value: 95000, label: "95.000 km" },
  { value: 100000, label: "100.000 km" },
  { value: 105000, label: "105.000 km" },
  { value: 110000, label: "110.000 km" },
  { value: 115000, label: "115.000 km" },
  { value: 120000, label: "120.000 km" },
  { value: 125000, label: "125.000 km" },
  { value: 150000, label: "125.000 km - 150.000 km" },
  { value: 175000, label: "150.000 km - 175.000 km" },
  { value: 200000, label: "175.000 km - 200.000 km" },
  { value: 225000, label: "200.000 km - 225.000 km" },
  { value: 250000, label: "225.000 km - 250.000 km" },
  { value: 275000, label: "250.000 km - 275.000 km" },
  { value: 300000, label: "275.000 km - 300.000 km" },
  { value: 350000, label: "300.000 km - 350.000 km" }
];

export function mobileAwareSelect(name, onChange, options, isDisabled) {
  if (window.isMobileDevice()) {
    return (
      <Input
        type="select"
        name={name}
        onChange={evt => onChange({ label: name, value: evt.target.value })}
        disabled={isDisabled}
      >
        <option value={undefined}>Select...</option>
        {options.map((prop, key) => (
          <option key={key} value={prop.value}>
            {prop.label}
          </option>
        ))}
      </Input>
    );
  }

  return (
    <Select
      name={name}
      onChange={onChange}
      options={options}
      isDisabled={isDisabled}
      required
    />
  );
}
