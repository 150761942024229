/* eslint-disable react/no-string-refs */
import React from "react";
import { Redirect } from "react-router-dom";
import ReactLoader from "components/Loader/ReactLoader.jsx";
import { Form, Card, CardBody, CardFooter, Progress } from "reactstrap";
import FormFirst from "./FormFirst";
import FormSecond from "./FormSecond";
import NotificationAlert from "react-notification-alert";
import { createOpp, updateOpp, getPriceAsde, getPrice } from "AWS/aws";
import { UsePriceContext } from "contexts/PriceContext.jsx";
import { UseRdvContext } from "contexts/RdvContext.jsx";
import { UseTranslation } from "i18n/Translation";
import PropTypes from "prop-types";
import { getHorsepowerComboOptions, getGearBoxComboOptions } from "AWS/aws";

class PriceForm extends React.Component {
  shouldRequestPrice = false;
  displayThirdStep = false;
  constructor(params) {
    super(params);

    this.state = {
      forms: [FormFirst, FormSecond],
      index: 0,
      oppId: "",
      waitingPrice: false,
      progressOnWaiting: 0,
      updatePrice: false,
      loadingOpportunity: false,
      hpCombo: [],

      firstForm: {
        carModelComboId: null, // This one has nothing to do on the backend. Only used to get modelFeaturesOptions

        carBrand: null,
        carBrandId: null,
        carFirstRegistration: null,
        carModel: null,
        carModelId: null,
        carBody: null,
        carFuelEnum: null,
        carNbDoors: null
      },
      secondForm: {
        carHorsepower: null,
        carGearboxEnum: null,
        carMileage: null
      }
    };

    this.changeForm = this.changeForm.bind(this);
    this.onSubmit = this.onSubmit.bind(this);
    this.checkIsComplete = this.checkIsComplete.bind(this);
    this.onChange = this.onChange.bind(this);
    this.notify = this.notify.bind(this);
    this.getGearBoxes = this.getGearBoxes.bind(this);
  }

  getForm() {
    switch (this.state.index) {
      case 0:
        return this.state.firstForm;
      case 1:
        return this.state.secondForm;
      default:
        // eslint-disable-next-line no-console
        console.log("Something went wrong");
    }
  }

  notify(type, msg) {
    const options = {
      place: "tr", // Top Right
      message: (
        <div>
          <div>{msg}</div>
        </div>
      ),
      type,
      icon: "fas fa-exclamation-triangle",
      autoDismiss: 5
    };

    // eslint-disable-next-line react/no-string-refs
    this.refs.notificationAlert.notificationAlert(options);
  }

  changeForm() {
    const { index, forms } = this.state;

    if (index + 1 >= forms.length) return;

    this.setState({ index: index + 1 });
  }

  checkIsComplete() {
    // the check should be done on the first form only (and we now should test a select on the second form also)
    // since we use the library react-search we cannot mark a select as required
    if (this.state.index === 0) {
      const form = this.getForm();
      for (var property in form) {
        if (form[property]) {
          continue;
        }
        return false;
      }
    } else if (this.state.index === 1) {
      const form = this.getForm();

      for (var prop in form) {
        if (form[prop]) {
          continue;
        }

        return false;
      }
    }

    return true;
  }

  async getHPowerComboOptions() {
    const data = await getHorsepowerComboOptions(
      this.state.firstForm.carModelComboId,
      this.state.firstForm.carFirstRegistration,
      this.state.firstForm.carFuelEnum
    );

    return data.map(mdata => ({
      value: mdata.value,
      label: mdata.label
    }));
  }

  async getGearBoxes(powerKw) {
    const data = await getGearBoxComboOptions(
      this.state.firstForm.carModelComboId,
      this.state.firstForm.carFirstRegistration,
      this.state.firstForm.carFuelEnum,
      powerKw
    );

    return data.map(mdata => ({
      value: mdata.value,
      label: mdata.label
    }));
  }

  async onSubmit(e) {
    e.preventDefault();

    // Check the form is complete
    if (this.checkIsComplete()) {
      // Used to reset the component
      e.target.reset();

      // In case we were on the first form
      if (this.state.index === 0) {
        try {
          // Launch loader
          this.setState({ loadingOpportunity: true });

          const leadSource = localStorage.getItem("leadSource");
          let body = { ...this.state.firstForm };
          if (leadSource) {
            body = {
              ...body,
              leadSource
            };
          }

          // Create the opportunity on the backend
          var data = await createOpp(body);

          // We retrieve the options for the combo of the second form
          var comboItem = await this.getHPowerComboOptions();

          this.setState({
            oppId: data.id,
            loadingOpportunity: false,
            hpCombo: comboItem
          });
        } catch (err) {
          this.notify(
            "danger",
            this.props.translation.t("home.form.error.popup")
          );

          // Stop the loader
          this.setState({ loadingOpportunity: false });
        }
      } else if (this.state.index === 1) {
        updateOpp(this.state.oppId, this.state.secondForm).then(result => {
          this.props.rdvContext.updateBookingCalendars(result.bookingCalendars);

          getPriceAsde(this.state.oppId)
            .then(async price => {
              if (price.price === 0) {
                price = await getPrice(this.state.oppId);
              }

              if ("bookingCalendars" in price) {
                this.props.rdvContext.updateBookingCalendars(
                  price.bookingCalendars
                );
              }

              this.props.priceContext.updatePrice(price.price);
              this.setState({
                redirectToPrice: true
              });
            })
            .catch(async (err, dt) => {
              try {
                this.setState({
                  progressOnWaiting: 0
                });

                const price = await getPrice(this.state.oppId);
                this.props.priceContext.updatePrice(price.price);
                this.setState({
                  redirectToPrice: true
                });
              } catch (err) {
                // eslint-disable-next-line no-console
                console.log("ERROR ON REQUEST PRICE", err, dt);

                this.props.priceContext.updatePrice(0);
                this.setState({
                  redirectToPrice: true
                });
              }
            });
        });

        this.props.priceContext.updateSellerInfo(this.state.oppId, {
          carBrand: this.state.firstForm.carBrand,
          carModel: this.state.firstForm.carModel
        });
      }

      // If we were on the last step
      if (this.state.index === this.state.forms.length - 1) {
        // We launch the fake progress bar
        this.setState({ waitingPrice: true, progressOnWaiting: 0 });
        this.launchFakeProgressBar();
      } else {
        // Go to the next form
        this.changeForm();
      }
    } else {
      this.notify(
        "warning",
        this.props.translation.t("home.form.not.complete.popup")
      );
    }
  }

  launchFakeProgressBar() {
    var step_ms = 1500;
    var step_pc = (step_ms / 75000) * 100;
    var interval = window.setInterval(
      function() {
        if (this.state.progressOnWaiting > 100) {
          clearInterval(interval);
        }
        this.setState({
          progressOnWaiting: this.state.progressOnWaiting + step_pc
        });
      }.bind(this),
      step_ms
    );
  }

  onChange(e) {
    let data = this.getForm();
    data[e.target.name] = e.target.value;

    switch (this.state.index) {
      case 0:
        this.setState({ firstForm: data });
        break;
      case 1:
        this.setState({ secondForm: data });
        break;
      default:
        // eslint-disable-next-line no-console
        console.log("Something went wrong");
    }
  }

  render() {
    const FormComponent = this.state.forms[this.state.index];

    const { redirectToPrice, waitingPrice, hpCombo } = this.state;

    if (redirectToPrice && waitingPrice) {
      return <Redirect to={"result"} />;
    }

    const { translation } = this.props;

    return (
      <div>
        <NotificationAlert ref="notificationAlert" />
        <Form onSubmit={this.onSubmit}>
          <Card className="card-price-form card-form">
            <CardBody className="text-left">
              {this.state.waitingPrice ? (
                <div className="loading-progress">
                  <ReactLoader size={5} />
                  <Progress animated value={this.state.progressOnWaiting} />
                </div>
              ) : (
                <FormComponent
                  onChange={this.onChange}
                  hpCombo={hpCombo}
                  getGearBoxes={this.getGearBoxes}
                />
              )}
            </CardBody>
            <CardFooter>
              {this.state.waitingPrice ? null : (
                <div>
                  <button
                    className="btn btn-loader"
                    disabled
                    hidden={!this.state.loadingOpportunity}
                  >
                    <ReactLoader size={2} />
                  </button>
                  <input
                    type="submit"
                    className="btn btn-primary btn-lg rounded-pill"
                    hidden={this.state.loadingOpportunity}
                    value={
                      this.state.index === this.state.forms.length - 1
                        ? translation.t("home.form.btn.send")
                        : translation.t("home.form.btn.continue")
                    }
                  />
                </div>
              )}
            </CardFooter>
          </Card>
        </Form>
      </div>
    );
  }
}

PriceForm.propTypes = {
  priceContext: PropTypes.object.isRequired,
  rdvContext: PropTypes.object.isRequired,
  translation: PropTypes.object.isRequired
};

export default UsePriceContext(UseTranslation(UseRdvContext(PriceForm)));
