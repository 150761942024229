import Home from "layouts/Home/Home.jsx";
import Result from "layouts/Home/Result.jsx";

var indexRoutes = [
  { path: "/result/rdv", name: "Result", component: Result },
  { path: "/result", name: "Result", component: Result },
  { path: "/", name: "Home", component: Home }
];

export default indexRoutes;
