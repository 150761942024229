/* eslint-disable no-console */
import { API, Auth } from "aws-amplify";
import { authEmail, authPassword, emailToken } from "AWS/config.jsx";

export const getBrands = async makeId => {
  let apiName = "auditorApi";
  let path = "/models";
  let myInit = {
    queryStringParameters: {
      makeId
    }
  };
  return await API.get(apiName, path, myInit);
};

export const getModelComboOptions = async (makeId, year) => {
  let apiName = "auditorApi";
  let path = "/modelComboOptions";
  let myInit = {
    queryStringParameters: {
      makeId,
      year
    }
  };

  return await API.get(apiName, path, myInit);
};

export const getFuelComboOptions = async (modelComboId, year) => {
  let apiName = "auditorApi";
  let path = "/modelFeaturesOptions";
  let myInit = {
    queryStringParameters: {
      requestedFeature: "carFuel",
      modelComboId,
      year
    }
  };

  return await API.get(apiName, path, myInit);
};

export const getHorsepowerComboOptions = async (
  modelComboId,
  year,
  fueldId
) => {
  let apiName = "auditorApi";
  let path = "/modelFeaturesOptions";
  let myInit = {
    queryStringParameters: {
      requestedFeature: "carPowerKw",
      modelComboId,
      year,
      fueldId
    }
  };

  return await API.get(apiName, path, myInit);
};

export const getGearBoxComboOptions = async (
  modelComboId,
  year,
  fueldId,
  powerKw
) => {
  let apiName = "auditorApi";
  let path = "/modelFeaturesOptions";
  let myInit = {
    queryStringParameters: {
      requestedFeature: "carGearbox",
      modelComboId,
      year,
      fueldId,
      powerKw
    }
  };

  return await API.get(apiName, path, myInit);
};

export const createOpp = async (payload, dataKeys) => {
  let apiName = "auditorApi";
  let path = "/opp";
  let myInit = {
    body: {
      payload,
      dataKeys
    }
  };

  return await API.post(apiName, path, myInit);
};

export const updateOpp = async (oppId, payload, dataKeys) => {
  let apiName = "auditorApi";
  let path = "/opp";
  let myInit = {
    body: {
      oppId,
      payload,
      dataKeys
    }
  };

  return await API.put(apiName, path, myInit);
};

export const getOpp = async (oppId, dataKeys) => {
  let apiName = "auditorApi";
  let path = "/opp";
  let myInit = {
    queryStringParameters: {
      oppId,
      dataKeys:
        "vendorEmail,vendorFirstName,vendorName,seller_phone,carModel,carBrand"
    }
  };

  return await API.get(apiName, path, myInit);
};

export const getPrice = async oppId => {
  let apiName = "auditorApi";
  let path = "/price";
  let myInit = {
    queryStringParameters: {
      oppId
    }
  };
  return retryFunction(apiName, path, myInit);
};

export const getPriceAsde = async oppId => {
  let apiName = "auditorApi";
  let path = "/priceAsde";
  let myInit = {
    queryStringParameters: {
      oppId
    }
  };
  return await API.get(apiName, path, myInit);
};

export async function logUserInAsync() {
  try {
    let userLogged = await Auth.signIn(authEmail, authPassword);
    console.log("Signing user in");
    console.log(userLogged);
  } catch (e) {
    alert(e.message);
  }
}

export function logUserIn() {
  try {
    return Auth.signIn(authEmail, authPassword);
  } catch (e) {
    alert(e.message);
  }
}

export const sendEmail = async (subject, body) => {
  let apiName = "auditorApi";
  let path = "/email";
  let myInit = {
    body: {
      token: emailToken,
      payload: {
        subject,
        body
      }
    }
  };
  await API.post(apiName, path, myInit);
};

const delay = ms => new Promise(res => setTimeout(res, ms));

// Will retry to get with the parameters ("retries" more attemps)
async function retryFunction(apiName, path, myInit, retries = 1) {
  try {
    // If succeeded, returns the result
    return await API.get(apiName, path, myInit);
  } catch (err) {
    if (retries === 0) {
      // Could not get a result
      return Promise.reject(err);
    } else {
      // Retry to once more after 10 secs
      await delay(10000);
      return retryFunction(apiName, path, myInit, --retries);
    }
  }
}
