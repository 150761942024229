import React from "react";
import { Row } from "reactstrap";
import { UseTranslation } from "i18n/Translation";
import SectionPressBadge from "components/Sections/SectionPressBadge.jsx";
import PropTypes from "prop-types";

import "./SectionPressBadges.css";

class SectionPressBadges extends React.Component {
  render() {
    const { t } = this.props.translation;

    return (
        <section className="section-press-badges" id="press-badges">
        <div className="text-center">
          <h2 className="sgtitle">{t("home.pressSection.title")}</h2>
        </div>
          <Row>
            <SectionPressBadge
              img={require("assets/img/Lecho_logo.jpeg")}
              title={t("home.pressbadgessection.badge1.title")}
              link={t("home.pressbadgessection.badge1.link")}
            />
          </Row>
      </section>
    );
  }
}

SectionPressBadges.propTypes = {
  translation: PropTypes.object.isRequired
};

export default UseTranslation(SectionPressBadges);
