import React from "react";
import { Row, Col, Card, CardBody } from "reactstrap";
import PropTypes from "prop-types";

const ResultReprise = props => {
  const { priceContext, translation } = props;
  const { price, sellerInfo } = priceContext;
  const { carBrand, carModel } = sellerInfo;

  if (price === 0) {
    return (
      <div className="text-center mb-5">
        <h6>{translation.t("result.text.reprise.no.result")}</h6>
      </div>
    );
  }

  return (
    <div>
      <p className="lead">
        {translation
          .t("result.text.reprise")
          .replace("{carBrand}", carBrand)
          .replace("{carModel}", carModel)}
      </p>
      <h1 className="display-3 price">{price} EUR !</h1>
    </div>
  );
};

ResultReprise.propTypes = {
  priceContext: PropTypes.object.isRequired,
  translation: PropTypes.object.isRequired
};

const ResultHeader = props => {
  const { translation, priceContext } = props;

  if (priceContext.price !== 0) {
    localStorage.setItem("PRICE", priceContext.price);
    localStorage.setItem("OPPID", priceContext.oppId);
  }

  return (
    <Row>
      <Col md={{ size: 12, offset: 0 }} lg={{ size: 4, offset: 1 }}>
        <Card className="card-pricing">
          <CardBody className="align-items-center">
            <div className="p-4">
              <ResultReprise
                priceContext={priceContext}
                translation={translation}
              />
              <p className="mt-3">{translation.t("result.rdv.textAboveButton")}</p>
              <a className="btn btn-primary mt-0" href="#RDV">
                {translation.t("result.btn.rdv")}
              </a>
            </div>
          </CardBody>
        </Card>
      </Col>
      <Col md={12} lg={7}>
        <Card className="card-result-text">
          <CardBody className="text-left align-items-center d-flex">
            <blockquote className="py-2 mx-4 px-2">
              <p>{translation.t("result.text.paragraph1")}</p>
              <p>{translation.t("result.text.paragraph2")}</p>
              <p>{translation.t("result.text.paragraph3")}</p>
            </blockquote>
          </CardBody>
          <div>
              <p className="mt-3">{translation.t("result.text.callUs")}</p>
              <a
                className="btn btn-discrete"
                href="tel:+32477045674"
                role="button"
              >
                +32 477 04 56 74
              </a>
          </div>
        </Card>
      </Col>
    </Row>
  );
};

ResultHeader.propTypes = {
  priceContext: PropTypes.object.isRequired,
  translation: PropTypes.object.isRequired
};

export default ResultHeader;
